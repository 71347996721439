import React from 'react'
import styled from '@emotion/styled'

const SVG = styled.svg`
  padding: 0;
  width: 20px;
`

const DigitalIcon = () => {
  return (
    <SVG viewBox="0 0 32 37">
      <path
        d="M27.973 26.465L16 33.436 3.02 25.876V11.912l12.426 7.979a1.017 1.017 0 001.13 0l12.409-7.98v13.974l-1.01.581zM16 3.195l11.924 6.943-11.915 7.65-11.932-7.65L16 3.194zm14.99 6.893a1.015 1.015 0 00-.12-.418c-.003-.003-.003-.007-.005-.01 0-.002-.003-.004-.004-.005a1.013 1.013 0 00-.226-.259.906.906 0 00-.083-.064c-.02-.014-.037-.033-.058-.045l-13.99-8.149a.99.99 0 00-1.009 0L1.504 9.288a1.018 1.018 0 00-.504.88v16.299c0 .362.193.699.504.88l13.991 8.15a1.001 1.001 0 001.009 0l13.99-8.15c.04-.023.072-.055.108-.083.033-.026.07-.047.099-.077.036-.036.062-.079.092-.12.024-.03.052-.057.072-.092.003-.005.002-.01.005-.014a1.021 1.021 0 00.12-.415c.002-.027.01-.052.01-.08V10.169c0-.027-.008-.053-.01-.08h0z"
        fill="#000"
        stroke="#000"
        fill-rule="evenodd"
      />
    </SVG>
  )
}

export default DigitalIcon
