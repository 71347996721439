import React from 'react'
import styled from '@emotion/styled'

const SVG = styled.svg`
  padding: 0;
  width: 20px;
`

const DigitalIcon = () => {
  return (
    <SVG viewBox="0 0 32 36">
      <g fill="#000" stroke="#000" fill-rule="evenodd">
        <path d="M28.834 10.819V25.72l-1.005.572-11.909 6.863-12.912-7.442V10.825L15.92 3.382l11.912 6.865 1.002.572zm0 0V25.72 10.819zm2.006-.57c0-.028-.008-.053-.01-.08a.993.993 0 00-.12-.412l-.004-.01-.004-.004a.994.994 0 00-.225-.255 1.006 1.006 0 00-.083-.063c-.02-.014-.036-.032-.057-.044L16.42 1.358a.993.993 0 00-1.003 0L1.5 9.381a1 1 0 00-.501.867v16.046c0 .357.192.689.501.867l13.917 8.024a1.005 1.005 0 001.003 0l13.916-8.024c.04-.023.071-.055.107-.082.033-.025.069-.046.099-.076.036-.035.062-.077.091-.117.024-.03.052-.057.072-.091.003-.005.002-.01.005-.014a.992.992 0 00.119-.409c.002-.026.01-.051.01-.078V10.248z" />
        <path d="M16 12.298a5.873 5.873 0 00-5.867 5.867A5.874 5.874 0 0016 24.032a5.874 5.874 0 005.867-5.867A5.873 5.873 0 0016 12.298m0 13.754c-4.349 0-7.887-3.538-7.887-7.887s3.538-7.887 7.887-7.887 7.887 3.538 7.887 7.887-3.538 7.887-7.887 7.887" />
      </g>
    </SVG>
  )
}

export default DigitalIcon
