import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import { startCase } from 'lodash'

const Folios = ({ data, pageContext }) => {
  const folios = data.allContentfulFolio.edges
  const { basePath } = pageContext

  let ogImage

  try {
    ogImage = folios[0].node.heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout>
      <SEO title={startCase(basePath)} image={ogImage} />
      <Container>
        <CardList>
          {folios.map(({ node: folio }) => (
            <Card key={folio.id} {...folio} basePath={'folio'} />
          ))}
        </CardList>
      </Container>
      <Pagination context={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulFolio(limit: $limit, skip: $skip) {
      edges {
        node {
          title {
            title
          }
          category
          servicio
          id
          slug
          cardImage {
            fluid(maxWidth: 520) {
              ...GatsbyContentfulFluid
            }
            ogimg: resize(width: 520) {
              src
            }
          }
        }
      }
    }
  }
`

export default Folios
