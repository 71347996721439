import React from 'react'
import styled from '@emotion/styled'

const SVG = styled.svg`
  padding: 0;
  width: 20px;
`

const DigitalIcon = () => {
  return (
    <SVG viewBox="0 0 32 37">
      <path
        d="M28.984 25.884l-1.01.581-4.042 2.353V14.937c0-.37-.195-.711-.513-.896l-6.908-4.018a1.017 1.017 0 00-1.024 0L8.58 14.045a1.035 1.035 0 00-.512.896v13.876l-5.049-2.94V10.754L16 3.194l11.976 6.973 1.008.58v15.137zM16 33.436l-5.896-3.433c.006-.045.014-.088.014-.134V15.538L16 12.112l5.883 3.422v14.477L16 33.436zm14.99-23.348a1.016 1.016 0 00-.12-.418c-.003-.003-.003-.007-.005-.01 0-.003-.003-.004-.004-.006a1.005 1.005 0 00-.226-.258 1.012 1.012 0 00-.083-.064c-.02-.014-.037-.033-.058-.045l-13.99-8.149a.99.99 0 00-1.009 0L1.504 9.288a1.017 1.017 0 00-.504.88v16.298c0 .363.193.7.504.881l13.991 8.15a1.006 1.006 0 001.01 0l13.989-8.15c.04-.023.072-.055.108-.084.033-.025.07-.046.1-.076.035-.036.061-.079.091-.12.024-.03.052-.057.072-.092.003-.005.003-.01.005-.014a1.015 1.015 0 00.12-.415c.002-.027.01-.052.01-.08V10.168c0-.027-.008-.053-.01-.08h0z"
        fill="#000"
        stroke="#000"
        fill-rule="evenodd"
      />
    </SVG>
  )
}

export default DigitalIcon
