import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import DigitalIcon from '../icons/DigitalIcon'
import PublicidadIcon from '../icons/PublicidadIcon'
import DesignIcon from '../icons/DesignIcon'

const Folio = styled.li`
  background-color: #fff;
  position: relative;
  border-radius: 2px;
  margin: 0 0 32px;
  width: 100%;
  transition: 0.3s;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 32%')};
    max-width: 208px;
    margin: 32px 0 32px;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 32%')};
    max-width: 260px;
    margin: 18px 0 18px;
  }
  &:hover {
    box-shadow: 0 20px 30px -14px rgba(48, 54, 77, 0.32);
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 180px;
      @media screen and (min-width: ${props => props.theme.responsive.medium}) {
        padding-bottom: 144px;
      }
      @media screen and (min-width: ${props => props.theme.responsive.large}) {
        padding-bottom: 180px;
      }
    }
  }
`

const StyledImg = styled(Img)`
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
`

const Title = styled.h2`
  font-size: 1.385rem;
  font-weight: 600;
  line-height: 23px;
  text-transform: capitalize;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    font-size: 0.95rem;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: 1.125rem;
  }
`

const Category = styled.p`
  font-size: 1.154rem;
  margin: 10px 0;
  line-height: 17px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    font-size: 0.8rem;
    margin: 5px 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: 0.938rem;
  }
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    padding: 18px 21px;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: 1.385rem;
  }
`
const Service = styled.div`
  align-self: flex-end;
  margin-top: 1rem;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    svg {
      width: 16px;
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin-top: 0.5rem;
    svg {
      width: 20px;
    }
  }
`

const getServiceIcon = service => {
  switch (service) {
    case 'Publicidad': {
      return <PublicidadIcon />
    }

    case 'Digital': {
      return <DigitalIcon />
    }
    default: {
      return <DesignIcon />
    }
  }
}

const Card = ({
  slug,
  cardImage,
  title,
  publishDate,
  body,
  category,
  servicio,
  ...props
}) => {
  return (
    <>
      {cardImage && title && (
        <Folio featured={props.featured}>
          <Link to={`${props.basePath}/${slug}/`}>
            <StyledImg fluid={cardImage.fluid} backgroundColor={'#eeeeee'} />
            <Body>
              <Title>{title.title}</Title>
              <Category>{category}</Category>
              <Service>{getServiceIcon(servicio)}</Service>
            </Body>
          </Link>
        </Folio>
      )}
    </>
  )
}

export default Card
